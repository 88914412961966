import { formatDate } from "react-day-picker/moment";
import { dateFormat, locale } from "./config";
import md5 from "md5";

export const sort = (a, b) => {
  if (a < b) {
    return -1;
  } else {
    return 0;
  }
};

export const formatToDay = date =>
  date ? formatDate(date, dateFormat, locale).toString() : date;
export const formatToDate = date => (date ? date.slice(0, 10) : date);
export const returnColor = i => ` #${md5(i).slice(0, 6)}`;
