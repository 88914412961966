import * as React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import { REGISTER_USER_REQUEST } from "../../utils/authRequests";

class SignUpPage extends React.Component {
  state = {
    variables: {
      email: "",
      password: "",
      password_confirmation: "",
      first_name: "",
      last_name: ""
    }
  };

  _onChange = (name, e) => {
    this.setState({
      variables: {
        ...this.state.variables,
        [name]: e.target.value
      }
    });
  };
  render() {
    const { history, openAlert } = this.props;
    const { variables } = this.state;
    return (
      <div>
        <Card>
          <div className={styles.header}>
            <Card.Body>
              <Card.Title className={styles.title}>
                <span className={styles.forta}> FORTA </span> TASKMANAGER
              </Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </div>
        </Card>
        <form
          onSubmit={e => {
            e.preventDefault();

            REGISTER_USER_REQUEST(variables)
              .then(response => {
                openAlert({
                  alertVisible: true,
                  alertMessage: "Gefeliciteerd",
                  alertTitle: "Uw account is geactiveerd!"
                });
                history.push("/");
              })
              .catch(error => {
                openAlert({
                  alertVisible: true,
                  alertMessage: `${error.message} ${{ ...error.errors }.email}`
                });
              });
          }}
        >
          <div className={styles.login}>
            <Container>
              <Row>
                <Col md={{ span: 5, offset: 4 }}>
                  <label className={styles.formLabel}>Registreren</label>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 5, offset: 4 }}>
                  <input
                    className={styles.input}
                    type="text"
                    name="email"
                    data-test="username"
                    onChange={e => this._onChange("email", e)}
                    value={variables.email}
                    placeholder="Gebruikersnaam"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 5, offset: 4 }}>
                  <input
                    className={styles.input}
                    type="password"
                    name="password"
                    data-test="password"
                    onChange={e => this._onChange("password", e)}
                    value={variables.password}
                    placeholder="Wachtwoord"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 5, offset: 4 }}>
                  <input
                    className={styles.input}
                    type="password"
                    name="password_confirmation"
                    data-test="password"
                    onChange={e => this._onChange("password_confirmation", e)}
                    value={variables.password_confirmation}
                    placeholder="Herhaal Wachtwoord"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 5, offset: 4 }}>
                  <div className={styles.formButtons}>
                    <button
                      type="submit"
                      data-test="submit"
                      className={styles.firstBtn}
                    >
                      Account activeren
                    </button>

                    <button
                      className={styles.secondBtn}
                      onClick={() => this.props.history.push("/")}
                    >
                      Inloggen
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </form>
      </div>
    );
  }
}

export default SignUpPage;
