import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import AttendeeAdd from "../../components/Attendee/AttendeeAdd";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import { convertDatetoISOFormat } from "../../config";
import styles from "./styles.module.scss";
import { Col, Row } from "react-bootstrap";
import TasksDeadline from "./TasksDeadline";
import TaskNote from "./TaskNote";
import TaskTitle from "./TaskTitle";
import TaskRadio from "./TaskRadio";
import { Mutation, Query } from "react-apollo";
import {
  GET_ALL_USERS,
  GET_MEETING_WITH_ARCHIVE_YEARS
} from "../../utils/queries";
import ProgessLarge from "../../components/Progress";
import { getItemStyle } from "./dropMenuStyle";
import TaskArchive from "./TaskArchive";
import { Fade } from "react-reveal";
import { FaTrash } from "react-icons/fa";
import { DELETE_TASK } from "../../utils/mutations";

// function getStyle(style, snapshot) {
//   if (!snapshot.isDropAnimating) {
//     return style;
//   }
//   const { moveTo, curve, duration } = snapshot.dropAnimation;
//   // move to the right spot
//   const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
//   // add a bit of turn for fun
//   const rotate = "rotate(0.1turn)";
//   // patching the existing style
//   return {
//     ...style,
//     transform: `${translate} ${rotate}`,
//     // slowing down the drop because we can
//     transition: `all ${curve} ${duration + 1}s`
//   };
// }
const Task = ({
  task,
  meeting,
  index,
  droppable,
  attendees,
  categoryIndex
}) => {
  const [currentTask, updateTask] = useState(task);
  useEffect(() => {
    updateTask(task);
  }, [task]);

  const removeTask = (mutation, taskId) => {
    mutation({
      variables: {
        id: parseInt(taskId)
      }
    });
  };

  return (
    <div when={task.id}>
      <Draggable
        isDragDisabled={droppable && categoryIndex !== 2 ? false : true}
        draggableId={`${currentTask.id}`}
        key={currentTask.id}
        index={index}
      >
        {(provided, snapshot) => (
          <div>
            <Row
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
              // style={getStyle(provided.draggableProps.style, snapshot)}
            >
              <Col md={5} className={styles.desktopTasks}>
                <div className={styles.checkContainer}>
                  {droppable && categoryIndex !== 2 ? (
                    <TaskArchive task={currentTask} meeting={meeting} />
                  ) : null}
                  {categoryIndex !== 2 ? (
                    <TaskRadio
                      updateTask={data => updateTask(data)}
                      task={currentTask}
                      meeting={meeting}
                    />
                  ) : null}
                </div>
                <div className={styles.taskContainer}>
                  <TaskTitle task={task} meeting={meeting} />
                </div>
                <div className={styles.taskContainer}>
                  <TaskNote task={task} meeting={meeting} />
                </div>
              </Col>
              <Col md={4} className={styles.desktopTasks}>
                <Row>
                  <Col>
                    <div className={styles.createdContainer}>
                      <p className={styles.createdP}>Aangemaakt</p>
                      <Typography variant="body2">
                        <Moment
                          date={Date.parse(
                            convertDatetoISOFormat(task.created_at)
                          )}
                          // format="DD-MM-YYYY HH:mm:ss"
                          format="MMM Do"
                        />
                      </Typography>
                    </div>
                  </Col>
                  {categoryIndex !== 2 ? (
                    <Col>
                      <div className={styles.deadlineContainer}>
                        <p className={styles.deadlineP}>Deadline</p>
                        <TasksDeadline
                          id={currentTask.id}
                          deadline={currentTask.deadline}
                          meetingId={meeting.id}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col md={3} className={styles.desktopTasks}>
                <Query query={GET_ALL_USERS}>
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <div>{<ProgessLarge visible />}</div>;
                    } else if (error) {
                      console.log(error);
                      return <p>error</p>;
                    } else if (droppable && categoryIndex !== 2) {
                      const { users } = data;
                      return (
                        <AttendeeAdd
                          addTask={true}
                          style={{ marginTop: "30px" }}
                          task={task}
                          icon={"user-rounded"}
                          right={true}
                          meeting={meeting}
                          users={users}
                          attendees={attendees}
                        />
                      );
                    }
                    return null;
                  }}
                </Query>
                <Mutation
                  mutation={DELETE_TASK}
                  refetchQueries={() => {
                    return [
                      {
                        query: GET_MEETING_WITH_ARCHIVE_YEARS,
                        variables: { id: meeting.id }
                      }
                    ];
                  }}
                >
                  {(mutation, { data }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "100%",
                        width: "50px",
                        cursor: "pointer"
                      }}
                    >
                      <div onClick={() => removeTask(mutation, task.id)}>
                        <FaTrash style={{ color: "#e5055e" }} />
                      </div>
                    </div>
                  )}
                </Mutation>
              </Col>
              <Col sm={12} className={styles.mobileTasks}>
                <div className={styles.checkContainer}>
                  <TaskArchive task={task} meeting={meeting} />
                  <TaskRadio task={task} meeting={meeting} />
                </div>
                <div className={styles.taskContainer}>
                  <TaskTitle task={task} meeting={meeting} />
                </div>
                <TaskNote task={task} meeting={meeting} />
              </Col>
              <Col
                sm={12}
                className={styles.mobileTasks}
                style={{ marginTop: "20px" }}
              >
                <Query query={GET_ALL_USERS}>
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <div>{<ProgessLarge visible />}</div>;
                    } else if (error) {
                      console.log(error);
                      return <p>error</p>;
                    } else {
                      const { users } = data;
                      if (droppable && categoryIndex !== 2) {
                        return (
                          <AttendeeAdd
                            addTask={true}
                            style={{ marginTop: "30px" }}
                            task={task}
                            icon={"user-rounded"}
                            right={true}
                            meeting={meeting}
                            users={users}
                          />
                        );
                      } else {
                        return <></>;
                      }
                    }
                  }}
                </Query>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div className={styles.createdContainer}>
                    <p className={styles.createdP}>Aangemaakt</p>
                    <Typography variant="body2">
                      <Moment
                        date={Date.parse(
                          convertDatetoISOFormat(task.created_at)
                        )}
                        // format="DD-MM-YYYY HH:mm:ss"
                        format="MMM Do"
                      />
                    </Typography>
                  </div>
                  <div className={styles.deadlineContainer}>
                    <p className={styles.deadlineP}>Deadline</p>
                    <TasksDeadline
                      id={task.id}
                      deadline={task.deadline}
                      meetingId={meeting.id}
                    />
                  </div>
                </div>
              </Col>
              <hr className={styles.underline} />
            </Row>
          </div>
        )}
      </Draggable>
    </div>
  );
};
export default Task;
