import React from "react";
import styles from "./styles.module.scss";
import { Scrollbar } from "../Scrollbar";
import cn from "classnames";

class Notepad extends React.Component {
  state = {
    isNotePadActive: false,
    notePadText: "",
    notePadHeight: 144
  };

  componentDidMount() {
    this.setState({ notePadText: this.props.value });
  }

  handleNotePadTextChange = e => {
    e.preventDefault();
    const notePadText = e.target.value;
    const notePadHeight = e.target.scrollHeight;
    this.setState({ notePadText, notePadHeight }
      ,() => {
        this.props.updateNoteMutation(this.state.notePadText)
        console.log(this.state.notePadText)
      });
  };

  render() {
    const { onClick, isActive, notePadLabel } = this.props;

    const { notePadHeight, notePadText } = this.state;

    const arrowClass = cn(styles.arrow, {
      [styles.isActive]: isActive
    });

    const textareaClass = cn(styles.textarea, {
      [styles.isActive]: isActive
    });

    return (
      <div className={styles.notepad}>
        <button className={styles.button} onClick={onClick}>
          <span className={styles.text}>{notePadLabel}</span>
          <div className={arrowClass}>></div>
        </button>
        <div>
          <Scrollbar>
            <textarea
              onChange={this.handleNotePadTextChange}
              style={{ height: notePadHeight }}
              className={textareaClass}
              value={notePadText}
            />
          </Scrollbar>
        </div>
      </div>
    );
  }
}

export default Notepad;
