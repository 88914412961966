import React from "react";
import DateLogo from "../../assets/date.svg";
import DatePicker from "react-datepicker";
import styles from "./styles.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import { convertDatetoISOFormat } from "../../config";
import './index.css';

class Deadline extends React.Component {
  state = {
    startDate: "",
    preview: false,
    currentDeadline: this.props.currentDeadline
  };

  componentDidMount() {
    this.setState({
      preview: this.props.preview,
      currentDeadline: this.props.currentDeadline
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        preview: this.props.preview,
        currentDeadline: this.props.currentDeadline
      });
    }
  }

  handleChange = date => {
    this.setState(
      {
        startDate: date
      },
      () => {
        this.props.updateDeadline(this.state.startDate);
      }
    );
  };

  render() {
    const { currentDeadline } = this.state;
    const ExampleCustomInput = ({ value, onClick }) =>
      currentDeadline ? (
        <div className={styles.clickable} onClick={onClick}>
          <Typography variant="body2">
            <Moment
              date={Date.parse(convertDatetoISOFormat(currentDeadline))}
              // format="DD-MM-YYYY HH:mm:ss"
              format="MMM Do"
            />
          </Typography>
        </div>
      ) : (
          <div className={styles.dateContainer}>
            <button className={styles.deadlineBtn} onClick={onClick}>
              <img className={styles.dateIcon} src={DateLogo} alt="" />
            </button>
            {this.state.preview ? value ? <span><Moment
              date={Date.parse(convertDatetoISOFormat(value))}
              // format="DD-MM-YYYY HH:mm:ss"
              format="MMM Do"
            /></span> : null : null}
          </div>
        );
    return (
      <DatePicker
        popperPlacement="auto"
        className={styles.datePicker}
        selected={this.state.startDate}
        onChange={this.handleChange}
        customInput={<ExampleCustomInput />}
        isClearable={currentDeadline ? true : false}
      // dateFormat="MMMM eeee d, yyyy h:mm aa"
      />
    );
  }
}
export default Deadline;
