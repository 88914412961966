import React, { Component } from "react";
import "./App.css";
import { ApolloProvider } from "react-apollo";
import { Button } from "../src/components/Button";
import { client } from "./utils/apolloClientConfig";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import LoginPage from "./pages/login/Login";
import SignUpPage from "./pages/register/SignUp";
import { userLoggedIn } from "./utils/getUserAuth";
import protectedRoutes from "./utils/protectedRoutes";
import Alert from "../src/components/Alert";
import PasswordForget from './pages/Password/PasswordForget';
import PasswordReset from './pages/Password/PasswordReset';

class App extends Component {
  state = {
    alertMessage: "",
    alertVisible: false,
    alertTitle: "",
    alertButtonLabel: ""
  };
  _openAlert = options => {
    this.setState({
      alertMessage: options.alertMessage,
      alertVisible: options.alertVisible,
      alertTitle: options.alertTitle,
      alertButtonLabel: options.alertButtonLabel
    });
  };
  render() {
    const {
      alertVisible,
      alertMessage,
      alertTitle,
      alertButtonLabel
    } = this.state;
    return (
      <ApolloProvider client={client()}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" render={props => (
                <LoginPage
                  {...props}
                  openAlert={options => this._openAlert(options)}
                />
              )} />
            <Route exact path="/password/forget" component={PasswordForget} />
            <Route exact path="/password/reset/:token" component={PasswordReset} />
            <Route
              exact
              path={"/signup"}
              render={props => (
                <SignUpPage
                  {...props}
                  openAlert={options => this._openAlert(options)}
                />
              )}
            />
            {protectedRoutes.map((prop, key) => {
              return (
                <PrivateRoute
                  key={key}
                  path={prop.path}
                  user={userLoggedIn}
                  component={prop.component}
                />
              );
            })}
          </Switch>
          <Alert
            visible={alertVisible}
            content={alertMessage}
            title={alertTitle}
            actions={
              <React.Fragment>
                <Button
                  style={{
                    padding: "15px 25px 15px 25px",
                    marginRight: "20px"
                  }}
                  primary
                  onClick={() => this.setState({ alertVisible: false })}
                >
                  {alertButtonLabel || "Ok"}
                </Button>
              </React.Fragment>
            }
          />
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;
