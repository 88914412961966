import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { DELETE_CATEGORY } from "../../utils/mutations";
import Modal from "react-bootstrap/Modal";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import { FaTrash } from "react-icons/fa";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const CategoryDelete = ({ category, meetingId }) => {
  const [isModalOpen, updateModelOpen] = useState(false);
  const deleteCategory = mutation => {
    mutation({
      variables: {
        id: category.id
      }
    });
    updateModelOpen(false);
  };

  return (
    <>
      <button
        className={styles.deleteCategoryTitle}
        onClick={() => updateModelOpen(!isModalOpen)}
      >
        <FaTrash className={styles.deleteCategoryTitle} style={{color: '#e5055e'}}/>
      </button>
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={() => updateModelOpen(false)}>
          <Mutation
            mutation={DELETE_CATEGORY}
            refetchQueries={() => {
              return [
                {
                  query: GET_MEETING_WITH_ARCHIVE_YEARS,
                  variables: { id: meetingId }
                }
              ];
            }}
          >
            {(mutation, { data }) => (
              <>
                <Modal.Body>
                  Wilt u de category "{category.name}" verwijderen?
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className={styles.cancelDeleteCategoryPopupBtn}
                    variant="secondary"
                    onClick={() => updateModelOpen(false)}
                  >
                    Nee
                  </Button>
                  <Button
                    className={styles.deleteCategoryPopupBtn}
                    variant="primary"
                    onClick={() => deleteCategory(mutation)}
                  >
                    Ja
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Mutation>
        </Modal>
      )}
    </>
  );
};

export default CategoryDelete;
