import React,{useState,useRef, useEffect} from 'react';
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import { Mutation } from "react-apollo";
import styles from "./styles.module.scss";
import { EDIT_MEETING } from "../../utils/mutations";

const MeetingTitle = ({meeting}) =>{
    const wrapperRef = useRef(null);
    const [title,setTitle] = useState(meeting.name)    
    const [isTitleEditable,setTitleEditable] = useState(false);
    

    const useOutsideAlerter = ref=> {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setTitleEditable(false)
        }
      }
  
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });
    }
    useOutsideAlerter(wrapperRef);

    const handleTitleSubmit = (e, editMeeting) => {
        e.preventDefault();
        if (title !== null) {
          editMeeting({
            variables: { name: title, id: meeting.id }
          });
          setTitleEditable(false)
        }
      };
    return  <Mutation
    mutation={EDIT_MEETING}
    refetchQueries={() => {
      return [
        {
          query: GET_MEETING_WITH_ARCHIVE_YEARS,
          variables: { id: meeting.id }
        }
      ];
    }}
  >
    {(editMeeting, { data }) => (
      <div ref={wrapperRef}>
      {isTitleEditable ? (
        <form onSubmit={e => handleTitleSubmit(e, editMeeting)}>
          <input
            onChange={(e) => setTitle(e.target.value)}
            className={styles.meetingNameInput}
            value={title}
          />
        </form>
      ) : (
        <h1
          onClick={() => setTitleEditable(!isTitleEditable)}
          className={styles.meetingName}
        >
          {title}
        </h1>
      )}
    </div>
    )}
  </Mutation>
};

export default MeetingTitle;