import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { EDIT_CATEGORY } from "../../utils/mutations";
import { GET_MEETING_WITH_ARCHIVE_YEARS, GET_MY_TASKS } from "../../utils/queries";
import styles from "./styles.module.scss";

const CategoryTitle = ({
  name,
  meetingId,
  id,
  isTitleEditable,
  updateTitleEditable
}) => {
  const [title, setTitle] = useState(name);
  const handleCategoryTitleSubmit = (e, editCategory) => {
    e.preventDefault();
    if (title !== null) {
      editCategory({
        variables: {
          name: title,
          id: id
        }
      });

      updateTitleEditable(false);
    }
  };
  return (
    <>
      {isTitleEditable ? (
        <Mutation
          mutation={EDIT_CATEGORY}
          refetchQueries={() => {
            return [
              {
                query: GET_MEETING_WITH_ARCHIVE_YEARS,
                variables: { id: meetingId }
              },
              {
                query: GET_MY_TASKS,
                variables: { meeting_list_id: meetingId }
              }
            ];
          }}
        >
          {(mutation, { data }) => (
            <>
              <form onSubmit={e => handleCategoryTitleSubmit(e, mutation)}>
                <input
                  className={styles.changeCategoryTitleInput}
                  type="text"
                  onChange={e => setTitle(e.target.value)}
                  name="newCategoryTitle"
                  value={title}
                />
              </form>
            </>
          )}
        </Mutation>
      ) : (
        <span>{title}</span>
      )}
    </>
  );
};

export default CategoryTitle;
