import React, { Component } from "react";
import { logUserOut } from "../../utils/getUserAuth";
import MenuLogo from "../../assets/menu.svg";
import Signout from "../../assets/signout-pink.svg";
import styles from "./styles.module.scss";
import {
  Dropdown,
  Row,
  OverlayTrigger,
  Popover,
  ButtonToolbar
} from "react-bootstrap";

class AppMenu extends Component {
  state = {};

  logout = () => {
    const { history } = this.props;
    logUserOut();
    history.push("/login");
  };

  render() {
    return (
      <div>
        <ButtonToolbar>
          <OverlayTrigger
            trigger={["click", "focus"]}
            placement={"bottom-start"}
            overlay={
              <Popover>
                <Popover.Content>
                  <Dropdown.Item onClick={this.logout}>
                    <img className={styles.signoutImg} src={Signout} alt="" />
                    Uitloggen
                  </Dropdown.Item>
                </Popover.Content>
              </Popover>
            }
          >
            <Row>
              <button className={styles.menuPopBtn}>
                <img src={MenuLogo} alt="" className={styles.menuLogo} />
              </button>
              <h5 className={styles.h5}>
                <span className={styles.forta}> FORTA </span>
                TASKMANAGER
              </h5>
            </Row>
          </OverlayTrigger>
        </ButtonToolbar>
      </div>
    );
  }
}

export default AppMenu;
