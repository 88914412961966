import React from "react";
import Svg from "../Svg";

class DeleteButton extends React.Component {
    render() {
        return (
            <Svg
                onClick={() => this.props.onClick(this.props.meetingId, this.props.meetingTitle) }
                name="plus"
                style={{
                    width: "24px",
                    height: "24px",
                    marginTop: '3px',
                    transform: 'rotate(45deg)',
                    zIndex: '1000',
                    cursor: 'pointer'
                }}
            />
        );
    }
}

export default DeleteButton;
