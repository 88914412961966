import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { EDIT_CATEGORY } from "../../utils/mutations";
import { GET_MEETING_WITH_ARCHIVE_YEARS, GET_MY_TASKS } from "../../utils/queries";
import { FaRegArrowAltCircleUp, FaRegArrowAltCircleDown } from "react-icons/fa";

import styles from "./styles.module.scss";

const CategoryOrder = ({
  id,
  name,
  order,
  meetingId,
  index,
  categories,
  refetch
}) => {
  const [isClickAble, setClickAble] = useState(true);
  const handleUpdateCategoryOrder = (editCategory, order) => {
    if (isClickAble) {
      editCategory({
        variables: {
          order,
          id,
          name
        }
      });
    }
    setClickAble(false);
    refetch();
  };

  const renderUpButton = mutation => {
    const max = categories.reduce((prev, current) =>
      prev.order > current.order ? prev : current
    );
    if (max.order !== order) {
      return (
        <button
          className={styles.categoryDown}
          onClick={() => handleUpdateCategoryOrder(mutation, order + 1)}
        >
          <FaRegArrowAltCircleDown style={{ width: "25px", height: "25px" }} />
        </button>
      );
      // } else {
      //   return <div style={{ width: "16px" }}></div>;
    }
  };

  const renderDownButton = mutation => {
    if (index !== 3) {
      return (
        <button
          className={styles.categoryUp}
          onClick={() => handleUpdateCategoryOrder(mutation, order - 1)}
        >
          <FaRegArrowAltCircleUp style={{ width: "25px", height: "25px" }} />
        </button>
      );
      // } else {
      //   return <div style={{ width: "16px" }}></div>;
    }
  };

  return (
    <Mutation
      mutation={EDIT_CATEGORY}
      refetchQueries={() => {
        return [
          {
            query: GET_MEETING_WITH_ARCHIVE_YEARS,
            variables: { id: meetingId }
          },
          {
            query: GET_MY_TASKS,
            variables: { meeting_list_id: meetingId }
          }
        ];
      }}
    >
      {(mutation, { data }) => (
        <div>
          {renderUpButton(mutation)}
          {renderDownButton(mutation)}
        </div>
      )}
    </Mutation>
  );
};

export default CategoryOrder;
