import React, {useState} from 'react';
import {GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import {Mutation } from "react-apollo";
import { EDIT_TASK} from "../../utils/mutations";
import StyledRadio from '../StyledRadio';

const TaskRadio = ({meeting, task,updateTask}) => {
  const [isCompleted,setComplete] = useState(task.is_completed);
  const handleTaskComplete = (event, editTask) => {
    event.preventDefault();
    try {
      editTask({
        variables: {
          id: task.id,
          is_completed: !isCompleted,
          meeting_list_id: meeting.id
        }
      });
      setComplete(!isCompleted)
      updateTask({...task,is_completed: !isCompleted})
    } catch (e) {
      console.log("err", e);
    }
  };  

  return <Mutation
          mutation={EDIT_TASK}
          refetchQueries={() => {
          return [
            {
                query: GET_MEETING_WITH_ARCHIVE_YEARS,
                variables: {
                    id: meeting.id
                }
            }
        ];
        }}
        >
          {(editTask, {data}) => ( 
            <> 
              <StyledRadio
                checked={isCompleted}
                onClick={e => handleTaskComplete(e, editTask)}/> 
            </>
            )}
    </Mutation>
}

export default TaskRadio;
