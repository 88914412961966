import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Svg from "../Svg";
import Tooltip from 'react-tooltip-lite';

const getFirstCharacterInString = string =>
  string ? string.substring(0, 1) : "";

export default ({
  firstWord = "",
  secondWord = "",
  style,
  onClick,
  content,
  ...rest
}) => {
  return (
    <Avatar
      style={{
        width: "25px",
        height: "25px",
        fontSize: 9,
        // marginRight: 15,
        border: "1px solid white",
        lineHeight: "11px",
        cursor: onClick ? "pointer" : "normal",
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      {firstWord || secondWord ? (
          <Tooltip content={firstWord + " " + secondWord} direction="down" background="#6d6d6d" color="white">
            <span>{`${getFirstCharacterInString(
              firstWord,
            )}${getFirstCharacterInString(secondWord)}`}</span>
          </Tooltip>
      ) : (
        <Svg name="person-white" style={{ width: "15px", height: "15px" }} />
      )}
    </Avatar>
  );
};
