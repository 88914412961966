import { ApolloClient, InMemoryCache, from } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { token, logUserOut } from "./getUserAuth";

const REACT_APP_API_GQL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/graphql'
    // : 'https://taskmanager.holylabs.nl/graphql';
    : 'https://taskmanager.fortagroep.nl/project/graphql';

export const client = () => {
  const httpLink = createHttpLink({
    uri: REACT_APP_API_GQL
  });

const authMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (networkError){
    console.log(networkError);
    logUserOut();
  } return  true;
});

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  const authHeaders = authLink.concat(httpLink);

  return new ApolloClient({
    link: from([
      authMiddleware,
      authHeaders,
    ]),
    cache: new InMemoryCache({
      //dataIdFromObject: object => object.id,
    }),
    clientState: {
      defaults: {},
      resolvers: {},
      typeDefs: ``
    }
  });
};
