import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { defaultErrorTitle } from "../../resources/intl";

export const Alert = ({
  visible = false,
  title = defaultErrorTitle,
  content = "",
  actions
}: {
  visible?: boolean;
  title: string;
  content: string;
  actions: React.ReactNode;
}) => {
  return (
    <div>
      <Dialog
        open={visible}
        //   onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </div>
  );
};

export default Alert;
