export const tokenExpiredPopupTitle = "Please sign in";
export const tokenExpiredPopupMessage = "Your login has expired";

export const loginTitle = "Inloggen";
export const loginEmailPlaceholder = "Gebruikersnaam";
export const loginPasswordPlaceholder = "Wachtwoord";
export const signupButtonTitle = "Account activeren";

export const signupTitle = "Registreren";
export const signUpSuccessfulPopupTitle = "Congratulations";
export const signUpSuccessfulPopupMessage =
  "Your user was successfully registered";

export const myTasksPageTitle = "Mijn Taken";
export const archivePageTitle = "Archief";
export const meetingsTitle = "Vergaderingen";

export const notAvailableLabel = "N/A";

export const addMeetingPlaceholder = "Vul hier de naam in";
export const addMeetingButtonTitle = "Vergaderingen toevoegen";

export const addTaskButtonLabel = "Taak toevoegen";

export const addCategoryLabel = "Categorie toevoegen";
export const addCategoryPlaceholder = "Nieuwe categorie";

export const defaultErrorTitle = "Oops Something went wrong";
export const defaultPopupButtonTitle = "Ok";

export const datePickerSelectDateButtonLabel = "Opslaan";
export const datePickerCancelButtonLabel = "Cancel";

export const inputLabelVergeten = "Vergeten?";

export const notePadLabel = "Kladblok";

export const leftMenuSettingsLabel = "Settings";
export const leftMenuLogoutLabel = "Uitloggen";

export const rightMenuOptionLabel = "Opties";
export const rightMenuFilterLabel = "Filter";

export const tasksMenuMyTasksLabel = "Mijn taken";
export const tasksMenuAlleTakenLabel = "Alle taken";
export const tasksMenuOnvoTakenLabel = "Onvoltooide taken";
export const tasksMenuVolTakenLabel = "Voltooide taken";
export const tasksMenuSortOpLabel = "Sorteer op…";
export const tasksMenuAlfabetLabel = "Alfabet";
export const tasksMenuDeadlineLabel = "Deadline";
export const tasksMenuGebruikerLabel = "Gebruiker";

export const meetingMenuArchiveLabel = "Archiveren";
export const meetingMenuDeleteLabel = "Verwijderen";
export const meetingMenuLeaveLabel = "Verlaten";
export const meetingMenuCloseCategoryLabel = "Categorieën inklappen";
export const meetingMenuPrintLabel = "Printen";
export const meetingMenuDateLabel = "Aangemaakt";

export const taskTitleCantBeEmptyPopupTitle = "Formulier validatie";
export const taskTitleCantBeEmptyPopupMessage = "Titel van de taak mag niet leeg zijn.";
export const taskTitlePlaceHolder = "Vul hier de taak in";
export const taskDescriptionPlaceHolder = "Vul hier de notitie in";

export const meetingCantBeDeletedPopupMessage =
  "This meeting can't be deleted, because there are active tasks.";
export const meetingCantLeavePopupMessage =
  "You can't leave the meeting until you assign a new owner.";
export const meetingCantBeArchivedPopupMessage =
  "You can't archive this meeting, because there are active tasks.";

export const attendeeMenuVoorziter = "VOORZITTER";
export const attendeeMenuWijzigen = "Wijzigen";
export const attendeeMenuVerlaten = meetingMenuLeaveLabel;

//
export const deadline = "deadline";
export const alfabet = "alfabet";
export const assignee = "assignee";
export const categories = "categories";
