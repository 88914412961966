import React from "react";
import { user_id } from "../../utils/getUserAuth";
import { DragDropContext } from "react-beautiful-dnd";
import userCheck from "../../assets/user-check-pink.svg";
import styles from "./styles.module.scss";
import Categories from "../../components/Categories/Categories";
import { Query } from "react-apollo";
import { GET_MY_TASKS } from "../../utils/queries";
import ProgessLarge from "../../components/Progress";

const MyTask = props => {
  return (
    <div>
      <div className={styles.myTaskContainer}>
        <img src={userCheck} alt="" className={styles.userCheck} />
        <p className={styles.headerMytask}>Mijn actiepunten</p>
      </div>
      <Query
        fetchPolicy="cache-and-network"
        errorPolicy="ignore"
        query={GET_MY_TASKS}
      >
        {({ loading: loadingMeeting, error: errorMeeting, data}) => {
          if (loadingMeeting) {
            return <div>{<ProgessLarge visible />}</div>;
          } else if (errorMeeting) {
            return <p>{errorMeeting}</p>;
          } else {
            const { myTasks } = data;
            const myTask = myTasks.map(meeting => {
              return {
                ...meeting,
                categories: meeting.categories.map(category => {
                  return {
                    ...category,
                    tasks: category.tasks.filter((task, index, tasks) => {
                      if (task.assignee) return task.assignee.id === user_id;
                    })
                  };
                })
              };
            });
            return myTask.map(meeting => (
              <DragDropContext>
                <Categories
                  filters={{
                    showComplete: false,
                    showArchive: false,
                    chosenYear: null
                  }}
                  key={meeting.id}
                  meeting={meeting}
                  droppable={false}
                />
              </DragDropContext>
            ));
          }
        }}
      </Query>
    </div>
  );
};

export default MyTask;
