import React from 'react';
import styles from './styles.module.scss';
const AddMeetingCount = ({meeting:{categories}}) => {
    const getTotalTasks = () =>{
        let count = 0;
        categories.forEach(category => category.tasks.forEach(task => count += 1))
        return count;
    }

    const getTotalCompletedTask = () =>{
        let count = 0;
        categories.forEach(category => category.tasks.forEach(task =>{
            if(task && task.is_completed === true){
                count += 1
            }
        }))
        return count;
    };


return <span className={styles.count}>  {getTotalCompletedTask()}/{getTotalTasks()} </span>
};

export default AddMeetingCount;
