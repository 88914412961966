import React from "react";
import ReactSVG from "react-svg";

const Svg = ({ name, style, ...rest }) => {
    return (
        name && (
            <ReactSVG
                {...rest}
                svgStyle={style}
                src={require(`../../assets/${name}.svg`).toString()}
            />
        )
    );
};

export default Svg;
