import * as React from 'react'
import styles from './styles.module.scss'

export const Scrollbar = ({ children, height, width }) => {
  return (
    <div style={{ width: width, height: height }} className={styles.scrollbar}>
      {children}
    </div>
  )
}
