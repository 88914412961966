import React from "react";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import { Mutation } from "react-apollo";
import { EDIT_TASK } from "../../utils/mutations";
import styles from "./styles.module.scss";
import archivePink from "../../assets/archive-pink.svg";
import { user_id } from "../../utils/getUserAuth";

const TaskArchive = ({ meeting, task }) => {
  const handleTaskComplete = (event, editTask) => {
    event.preventDefault();
    try {
      editTask({
        variables: {
          id: task.id,
          archive: true,
          meeting_list_id: meeting.id
        }
      });
    } catch (e) {
      console.log("err", e);
    }
  };

  return (
    <Mutation
      mutation={EDIT_TASK}
      refetchQueries={() => {
        return [
          {
            query: GET_MEETING_WITH_ARCHIVE_YEARS,
            variables: {
              id: meeting.id
            }
          }
        ];
      }}
    >
      {(editTask, { data }) => (
        <>
          {task.is_completed ? (
            meeting.creator.id === user_id ? (
              <button
                onClick={e => handleTaskComplete(e, editTask)}
                className={styles.archiveButton}
              >
                <img className={styles.archivePink} src={archivePink} alt="" />
              </button>
            ) : null
          ) : null}
        </>
      )}
    </Mutation>
  );
};

export default TaskArchive;
