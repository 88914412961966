import * as React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

export const Button = ({
  primary,
  secondary,
  children,
  onClick,
  hoverEnter,
  hoverOut,
  marginRight,
  style,
  disabled,
  dark,
}: {
  primary?: boolean;
  secondary?: boolean;
  dark?: boolean;
  marginRight?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  hoverEnter?: () => void;
  hoverOut?: () => void;
  style?: Object;
  disabled?: boolean;
}) => {
  let className = cn(styles.btn, styles.width, {
    [styles.primary]: primary,
    [styles.secondary]: secondary,
    [styles.marginright]: marginRight,
    [styles.disabled]: disabled,
    [styles.dark]: dark,
  });

  return (
    <button
      onClick={onClick}
      onMouseEnter={hoverEnter}
      onMouseOut={hoverOut}
      className={className}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
