import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { ADD_CATEGORY_TO_MEETING } from "../../utils/mutations";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import styles from "./styles.module.scss";

const CategoryCreate = ({ meetingId, order, callback }) => {
  const [name, setName] = useState("");
  const [isNameEditable, setNameEditable] = useState(false);
  const handleCategorySubmit = (e, createCategory) => {
    e.preventDefault();

    if (name.length < 1) {
      return false;
    }

    createCategory({
      variables: {
        name,
        meeting_list_id: meetingId,
      }
    });
    setNameEditable(!isNameEditable);
    setName("");
    callback();
  };

  return (
    <Mutation
      mutation={ADD_CATEGORY_TO_MEETING}
      refetchQueries={() => {
        return [
          {
            query: GET_MEETING_WITH_ARCHIVE_YEARS,
            variables: { id: meetingId }
          }
        ];
      }}
    >
      {(createCategory, data) => (
        <form
          onSubmit={e => {
            handleCategorySubmit(e, createCategory);
          }}
        >
          <input
            className={styles.addInput}
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </form>
      )}
    </Mutation>
  );
};

export default CategoryCreate;
