import React from "react";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import { Mutation, Query } from "react-apollo";
import ProgessLarge from "../../components/Progress/Progress.tsx";
import { DragDropContext } from "react-beautiful-dnd";
import { EDIT_TASK } from "../../utils/mutations";
import AttendeeMenu from "../../components/Attendee/AttendeeMenu";
import Categories from "../../components/Categories/Categories";
import CategoryAdd from "../../components/Categories/CategoryAdd";
import MeetingTitle from "./MeetingTitle";

import { Redirect } from "react-router-dom";
import MeetingFilter from "./MeetingFilter";

class Meeting extends React.Component {
  state = {
    meetingId: this.props.match.params.id,
    filters: {
      showComplete: false,
      showArchive: false,
      chosenYear: ""
    }
  };

  componentDidMount() {
    this.setState({
      meetingId: this.props.match.params.id,
      filters: {
        showComplete: false,
        showArchive: false,
        chosenYear: ""
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({
        meetingId: this.props.match.params.id,
        filters: {
          showComplete: false,
          showArchive: false,
          chosenYear: ""
        }
      });
    }
  }

  onDragEnd = (result, meetingList, editTask) => {
    if (result.destination) {
      const {
        draggableId,
        destination: { droppableId, index },
        destination,
        source,
        reason
      } = result;
      if (!destination || reason === "CANCEL") {
        return;
      }
      if (droppableId === source.droppableId && index === source.index) {
        return;
      }
      const variables = {
        id: parseInt(draggableId),
        order: index + 1,
        category_id: parseInt(destination.droppableId),
        meeting_list_id: meetingList.id
      };
      try {
        editTask({ variables });
      } catch (e) {
        console.log(e);
      }
    }
  };

  updateCompleted(showComplete, showArchive, chosenYear) {
    this.setState({ filters: { showComplete, showArchive, chosenYear } });
  }

  render() {
    const { meetingId, filters } = this.state;
    return (
      <Query
        fetchPolicy="cache-and-network"
        errorPolicy="ignore"
        query={GET_MEETING_WITH_ARCHIVE_YEARS}
        variables={{ id: meetingId }}
        notifyOnNetworkStatusChange={true}
      >
        {({
          loading: loadingMeeting,
          error: errorMeeting,
          data: meetingData,
          refetch
        }) => {
          if (loadingMeeting) {
            return <div>{<ProgessLarge visible />}</div>;
          } else if (errorMeeting) {
            return <p>{errorMeeting}</p>;
          } else {
            const { meetingList, getArchiveYears } = meetingData;
            const archivedYears = getArchiveYears
              ? getArchiveYears.map(archive => {
                  return {
                    ...archive,
                    label: archive.archive,
                    value: archive.archive
                  };
                })
              : null;
            if (meetingList) {
              return (
                <Mutation
                  mutation={EDIT_TASK}
                  refetchQueries={() => {
                    return [
                      {
                        query: GET_MEETING_WITH_ARCHIVE_YEARS,
                        variables: { id: meetingId }
                      }
                    ];
                  }}
                >
                  {editTask => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <MeetingTitle meeting={meetingList} />
                        <MeetingFilter
                          getArchiveYears={archivedYears}
                          updateCompleted={(
                            showComplete,
                            showArchive,
                            chosenYear
                          ) =>
                            this.updateCompleted(
                              showComplete,
                              showArchive,
                              chosenYear
                            )
                          }
                          meeting={meetingList}
                        />
                      </div>
                      <DragDropContext
                        onDragEnd={result =>
                          this.onDragEnd(result, meetingList, editTask)
                        }
                      >
                        <AttendeeMenu
                          history={this.props.history}
                          refetch={refetch}
                          data={meetingList}
                        />
                        <Categories
                          refetch={refetch}
                          droppable={true}
                          filters={filters}
                          meeting={meetingList}
                        />
                      </DragDropContext>
                      <CategoryAdd meeting={meetingList} />
                    </>
                  )}
                </Mutation>
              );
            } else {
              return <Redirect to={"/"} />;
            }
          }
        }}
      </Query>
    );
  }
}
export default Meeting;
