import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import Arrowdown from "../../assets/arrow-small-down.svg";
import Arrowup from "../../assets/arrow-small-up.svg";
import { user_id } from "../../utils/getUserAuth";
import { Accordion, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import CategoryTitle from "../../components/Categories/CategoryTitle";
import Tasks from "../Tasks/Tasks";
import TaskAdd from "../Tasks/TaskAdd";
import { FaPencilAlt } from "react-icons/fa";
import { getQuestionListStyle } from "../Tasks/dropMenuStyle";
import CategoryOrder from "./CategoryOrder";
import CategoryDelete from "./CategoryDelete";
import Show from "../Show/Show";

const Category = ({
  meeting,
  category,
  index,
  droppable,
  categories,
  refetch,
  attendees
}) => {
  const [show, toggleShow] = useState(false);
  const [isTitleEditable, updateTitleEditable] = useState(false);
  const getImageName = () => (show ? "Arrowdown" : "Arrowdown");

  const imageName = getImageName();
  const imagesPath = {
    Arrowdown,
    Arrowup
  };
  return (
    <Droppable
      isDropDisabled={droppable && index !== 2 ? false : true}
      droppableId={`${category.id}`}
    >
      {(provided, snapshot) => (
        <>
          <Accordion
            s
            defaultActiveKey="1"
            ref={provided.innerRef}
            style={{ backgroundColor: "none" }}
          >
            <div className={styles.accordionHeader}>
              <Accordion.Toggle
                onClick={() => toggleShow(true)}
                as={Button}
                variant="link"
                eventKey="0"
                className={styles.cardBtn}
              >
                <div
                  style={{
                    backgroundImage: `url(${imagesPath[imageName]})`
                  }}
                  className={styles.accordionToggle}
                  onClick={() => toggleShow(!show)}
                  key={index}
                >
                  <div className={styles.categoryName}>
                    <CategoryTitle
                      updateTitleEditable={() =>
                        updateTitleEditable(!isTitleEditable)
                      }
                      isTitleEditable={isTitleEditable}
                      meetingId={meeting.id}
                      name={category.name}
                      id={category.id}
                    />
                  </div>
                </div>
              </Accordion.Toggle>
              <div className={styles.categoryButtonsContainer}>
                {index >= 3 ? (
                  <Show compare={[meeting.creator.id, user_id]}>
                    <CategoryOrder
                      refetch={refetch}
                      categories={categories}
                      index={index}
                      meetingId={meeting.id}
                      id={category.id}
                      name={category.name}
                      order={category.order}
                    />
                  </Show>
                ) : null}

                {index >= 3 ? (
                  <CategoryDelete meetingId={meeting.id} category={category} />
                ) : null}
                <button
                  className={styles.editCategoryTitle}
                  onClick={() => updateTitleEditable(!isTitleEditable)}
                >
                  <FaPencilAlt className={styles.editCategoryTitle} />
                </button>
              </div>
            </div>
            <Accordion.Collapse className={styles.collapse} eventKey="0">
              <>
                <Tasks
                  index={index}
                  droppable={droppable}
                  meeting={meeting}
                  tasks={category.tasks}
                  attendees={attendees}
                />
                <TaskAdd index={index} meeting={meeting} category={category} />
                {provided.placeholder}
              </>
            </Accordion.Collapse>
          </Accordion>
          {index === 2 && <h2 className={styles.categoryTitle}>Actiepunten</h2>}
        </>
      )}
    </Droppable>
  );
};

export default Category;
