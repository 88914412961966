import React, { Component } from "react";
import { logUserOut } from "../../utils/getUserAuth";
// import Signout from "../../assets/signout-pink.svg";
import styles from "./styles.module.scss";
import { FaSignOutAlt } from "react-icons/fa";

class MobileAppMenu extends Component {
  state = {};

  logout = () => {
    const { history } = this.props;
    logUserOut();
    history.push("/login");
  };

  render() {
    return (
      <div>
        <button onClick={this.logout} className={styles.logoutBtn}>
          {/* <img className={styles.signoutImg} src={Signout} alt="" />
          Uitloggen */}

          <FaSignOutAlt className={styles.logoutBtn} />
        </button>
      </div>
    );
  }
}

export default MobileAppMenu;
