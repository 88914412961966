import React, { useState } from "react";
import { Row } from "react-bootstrap";
import CategoryCreate from "./CategoryCreate";
import styles from "./styles.module.scss";
import Plus from "../../assets/plus.svg";

const CategoryAdd = ({ meeting }) => {
  const [open, toggleOpen] = useState(false);
  return (
    <>
      {open ? (
        <CategoryCreate
          meetingId={meeting.id}
          order={meeting.categories.length}
          callback={() => toggleOpen(!open)}
        />
      ) : null}
      <button
        className={styles.addCategoryBtn}
        type="button"
        onClick={() => toggleOpen(!open)}
      >
        <Row>
          {open ? null : (
            <img className={styles.CategoryPlusIcon} src={Plus} alt="" />
          )}
          <p className={styles.p}>
            {open ? "Annuleren" : "Category toevoegen"}
          </p>
        </Row>
      </button>
    </>
  );
};

export default CategoryAdd;
