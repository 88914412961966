import React,{useState,useEffect} from "react";
import Category from "./Category";
import { Fade } from "react-reveal";

const Categories = ({
  meeting,
  droppable,
  filters: { showComplete, showArchive, chosenYear },
  refetch
}) => meeting.categories
    .filter(el => (droppable ? el : el.tasks.length > 0))
    .sort((a, b) => (a.order < b.order ? true : false))
     .map(el =>
      showComplete
        ? el
        : { ...el, tasks: el.tasks.filter(task => !task.is_completed) }
    )
    .map(el => showArchive
        ? {
            ...el,
            tasks: el.tasks.filter(
              task => task.archive === null ? task : task.archive === chosenYear.toString()
            )
          }
        : { ...el, tasks: el.tasks.filter(task => !task.archive) }
    )

    .map((category, index, categories) => (
      <div key={index}>
        {console.log(categories)}
        <Category
          refetch={refetch}
          categories={categories}
          droppable={droppable}
          meeting={meeting}
          key={index}
          index={index}
          category={category}
          attendees={meeting.attendees}
        />
      </div>
    ));

export default Categories;
