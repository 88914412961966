import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "react-bootstrap/Modal";
import Slider from "@material-ui/core/Slider";
import { Button } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Filter from "../../assets/filter.svg";

const MeetingFilter = ({ updateCompleted, getArchiveYears }) => {
  const getMinArchive = data =>
    data.length > 0
      ? data.reduce(
          (min, p) => (p.archive < min ? p.archive : min),
          data[0].archive
        )
      : 0;
  const getMaxArchive = data =>
    data.length > 0
      ? data.reduce(
          (max, p) => (p.archive > max ? p.archive : max),
          data[0].archive
        )
      : 0;
  const [showComplete, updateComplete] = useState(false);
  const [showArchive, updateArchive] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [years] = useState(getArchiveYears);
  const [chosenYear, setYear] = useState(parseInt(getMaxArchive(years)));
  return (
    <>
      <button className={styles.MeetingFilter} onClick={() => setOpen(!isOpen)}>
        <img src={Filter} alt="" className={styles.MeetingFilter} />
      </button>
      <Modal size={"sm"} show={isOpen} onHide={() => setOpen(false)}>
        <Modal.Body>
          <div className={styles.innerModal}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showComplete}
                    onChange={() => updateComplete(!showComplete)}
                  />
                }
                label="Filter op compleet"
              />
            </FormGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showArchive}
                    onChange={() => {
                      updateArchive(!showArchive);
                      updateComplete(true);
                    }}
                  />
                }
                label="Filter op archive"
              />
            </FormGroup>
            {getMaxArchive(years) !== getMinArchive(years) ? (
              showArchive ? (
                <Slider
                  style={{ color: "#e5055e" }}
                  defaultValue={parseInt(getMaxArchive(years))}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks={years}
                  min={parseInt(getMinArchive(years))}
                  max={parseInt(getMaxArchive(years))}
                  onChange={(e, value) => setYear(value)}
                />
              ) : null
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Annuleren
          </Button>
          <Button
            onClick={() => {
              updateCompleted(showComplete, showArchive, chosenYear);
              setOpen(false);
              setYear(parseInt(getMaxArchive(years)));
            }}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MeetingFilter;
