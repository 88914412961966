import React from "react";
import { Mutation } from "react-apollo";
import { CREATE_TASK } from "../../utils/mutations";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import Deadline from "../../components/DatePicker/DatePicker";
import { formatToDay } from "../../utils/utils";
import styles from "./styles.module.scss";
import { FaTrash } from "react-icons/fa";

export default class CreateTask extends React.Component {
  state = {
    categoryId: this.props.categoryId,
    meetingId: this.props.meetingId,
    title: "",
    note: "",
    deadline: null,
    email: null,
    index: this.props.index
  };

  handleTaskSubmit = (e, createTask) => {
    e.preventDefault();

    const { title, note, deadline, email, categoryId, meetingId } = this.state;
    const variables = {
      title,
      description: note,
      category_id: categoryId,
      meeting_list_id: meetingId
    };

    if (email !== null) {
      variables.email = email;
    }

    if (deadline !== null) {
      variables.deadline = formatToDay(deadline);
    }

    createTask({
      variables
    });
    this.props.callback(categoryId);
  };

  render() {
    const { categoryId, index } = this.state;
    return (
      <Mutation
        mutation={CREATE_TASK}
        refetchQueries={() => {
          return [
            {
              query: GET_MEETING_WITH_ARCHIVE_YEARS,
              variables: { id: this.state.meetingId }
            }
          ];
        }}
      >
        {(createTask, data) => (
          <div className={styles.inputDeadlineContainer}>
            <form
              style={{ width: "70%" }}
              onSubmit={e => {
                this.handleTaskSubmit(e, createTask);
              }}
            >
              <input
                placeholder={
                  index === 2
                    ? "Vul hier het besluit in"
                    : "Vul hier de taak in"
                }
                className={styles.addInput}
                type="text"
                value={this.state.title}
                onChange={e => this.setState({ title: e.target.value })}
              />
              {index !== 2 && (
                <input
                  placeholder="Vul hier de notitie in"
                  className={styles.addInput}
                  type="text"
                  value={this.state.note}
                  onChange={e => this.setState({ note: e.target.value })}
                />
              )}

              <button className={styles.addBtn} type="submit">
                {/* <Row>
                <img className={styles.plusIcon} src={Plus} alt=""></img>
                <p className={styles.p}>Taak toevoegen</p>
              </Row> */}
              </button>

              <button
                className={styles.cancelBtn}
                type="button"
                onClick={() => this.props.toggleCancel(categoryId)}
              >
                <p className={styles.p}>
                  {" "}
                  <FaTrash
                    style={{
                      color: "#e5055e",
                      paddingRight: "10px",
                      width: "30px"
                    }}
                  />
                  Annuleren{" "}
                </p>
              </button>
            </form>
            {index !== 2 && (
              <Deadline
                preview={true}
                updateDeadline={deadline => this.setState({ deadline })}
              />
            )}
          </div>
        )}
      </Mutation>
    );
  }
}
