import React,{useEffect, useRef} from 'react';
import styles from './styles.module.scss';
import StyledAvatar from '../../components/StyledAvatar';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Svg from '../../components/Svg';
import {user_id} from '../../utils/getUserAuth'
import {Mutation} from 'react-apollo';
import {REMOVE_ATTENDEE_FROM_MEETING,} from '../../utils/mutations';
import {GET_MEETING_WITH_ARCHIVE_YEARS} from '../../utils/queries';
import Show from '../../components/Show/Show';

function useOuterClickNotifier(onOuterClick, innerRef) {
    useEffect(
      () => {
        // only add listener, if the element exists
        if (innerRef.current) {
          document.addEventListener("click", handleClick);
        }
  
        // unmount previous first in case inputs have changed
        return () => document.removeEventListener("click", handleClick);
  
        function handleClick(e) {
          innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e);
        }
      },
      [onOuterClick, innerRef] // invoke again, if inputs have changed
    );
  }
  const deleteAttendee = (mutation, user_id,meeting_id) => {
    try {
      mutation({
        variables: {
          user_id,
          meeting_id
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }

const AttendeeOverlay = ({attendee: {first_name,last_name,id,email},show,creator,close,toggle,meeting}) => {
    const innerRef = useRef(null);
    useOuterClickNotifier(
        // if you want to optimize performance a bit,
        // don't provide an anonymous function here
        // See link down under (*1)
        e => close(),
        innerRef
      );
    return (
        <div ref={innerRef} className={styles.attendeeOverlay + 
            (show ? ` ${styles.attendeeShow}`: ` `)}>
            {id === creator.id
                ? <span className={styles.attendeeTitle}>VOORZITTER</span>
                : null}
            <div className={styles.attendeeBottom}>
                <ListItemAvatar>
                    <StyledAvatar
                        firstWord={first_name}
                        secondWord={last_name}
                        style={{
                        width: 32,
                        height: 32,
                        fontSize: 13,
                        marginTop: 0,
                        marginRight: 0
                    }}/>
                </ListItemAvatar>
                <div className={styles.attendeeNames}>
                    <span className={styles.attendeeName}>{first_name} {last_name}</span>
                    <span className={styles.attendeeEmail}>{email}</span>
                </div>
                <Show compare={[creator.id,user_id]}>
                {id === creator.id
                    ? <button onClick={() => toggle()} className={styles.attendeeButton}>
                            Wijzigen
                        </button>
                    : 
                    <Mutation
                    mutation={REMOVE_ATTENDEE_FROM_MEETING}
                        refetchQueries={() => {
                          return [
                            {
                              query: GET_MEETING_WITH_ARCHIVE_YEARS,
                              variables: {id: meeting.id}
                            }
                          ];
                        }}>
                          {(update,data) => (
                             <button onClick={() => deleteAttendee(update,id,meeting.id)} className={styles.attendeeButton}>
                             <Svg
                                 name="leave"
                                 style={{
                                 width: "20px",
                                 height: "20px",
                                 fill: 'white',
                                 marginRight: 10
                             }}/>
                             Verlaten
                         </button>
                          )}
                    </Mutation>   
                }
                </Show>
            </div>
        </div>
    )
}

export default AttendeeOverlay;
