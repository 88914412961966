import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
// import { REQUEST_PASSWORD_FORGET } from "../../utils/mutations";
import { Link } from "react-router-dom";
// import { Mutation } from "react-apollo";
import axios from "axios";
import { PASSWORD_FORGOT } from "../../utils/authRequests";

const PasswordForget = () => {
  const [email, updateEmail] = useState("");

  const requestPassword = e => {
    e.preventDefault();
    updateEmail("");
    PASSWORD_FORGOT({email})
      .then(({ data: message }) => {
      console.log(message);
  });
  };

  return (
    <div>
      <Card>
        <div className={styles.header}>
          <Card.Body>
            <Card.Title className={styles.title}>
              <span className={styles.forta}> FORTA </span> TASKMANAGER
            </Card.Title>
          </Card.Body>
        </div>
      </Card>

      <Form
        onSubmit={e => {
          this.signInRequest(e);
        }}
      >
        <div className={styles.login}>
          <Container>
            <Row>
              <Col md={{ span: 5, offset: 4 }}>
                <label className={styles.formLabel}>Vul je email in</label>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 5, offset: 4 }}>
                <input
                  className={styles.input}
                  type="text"
                  name="email"
                  value={email}
                  onChange={e => updateEmail(e.target.value)}
                  placeholder="Voer je email in"
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 5, offset: 4 }}>
                <div className={styles.formButtons}>
                  <button
                    onClick={e => requestPassword(e)}
                    type="submit"
                    className={styles.firstBtn}
                  >
                    Aanvragen
                  </button>
                  <button className={styles.secondBtn}>
                    <Link to={"/logen"}>Ga terug</Link>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    </div>
  );
};

export default PasswordForget;
