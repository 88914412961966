import React, { useState } from "react";
import { EDIT_TASK } from "../../utils/mutations";
import { Mutation } from "react-apollo";
import { GET_MEETING_WITH_ARCHIVE_YEARS, GET_MY_TASKS } from "../../utils/queries";
import Deadline from "../../components/DatePicker/DatePicker";
import { formatToDay } from "../../utils/utils";

const TasksDeadline = ({ meetingId, deadline, id }) => {
  const [currentDeadline, setDeadline] = useState(deadline);
  const updateDeadline = (updateLine, taskId, date) => {
    const formated = formatToDay(date);
    const variables = {
      id: taskId,
      meeting_list_id: meetingId,
      deadline: formated
    };
    updateLine({ variables });
    setDeadline(formated);
  };
  return (
    <Mutation
      mutation={EDIT_TASK}
      refetchQueries={() => {
        return [
          {
            query: GET_MEETING_WITH_ARCHIVE_YEARS,
            variables: { id: meetingId }
          },
          {
            query: GET_MY_TASKS,
            variables: { meeting_list_id: meetingId }
          }
        ];
      }}
    >
      {(updateLine, { data }) => {
        return (
          <>
            <Deadline
              currentDeadline={currentDeadline}
              preview={false}
              updateDeadline={date => updateDeadline(updateLine, id, date)}
            />
          </>
        );
      }}
    </Mutation>
  );
};

export default TasksDeadline;
