import React from "react";
import styles from "./styles.module.scss";
import StyledAvatar from "../../components/StyledAvatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AttendeeOverlay from "./AttendeeOverlay";
import AttendeeAdd from "./AttendeeAdd";
import { Query } from "react-apollo";
import { returnColor } from '../../utils/utils'
import { GET_ALL_USERS } from "../../utils/queries";
import ProgessLarge from "../../components/Progress";
import Show from "../Show/Show";
import { user_id } from "../../utils/getUserAuth";
// import {Fade} from 'react-reveal';

class AttendeeMenu extends React.Component {
  state = {
    creator: {},
    attendees: [],
    meeting: {}
  };

  componentDidMount() {
    const {
      data,
      data: { creator, attendees, id, name }
    } = this.props;
    this.setState({
      data,
      creator,
      attendees: [creator, ...attendees],
      meeting: { id, name, attendees, creator }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      const {
        data,
        data: { creator, attendees, id, name }
      } = this.props;
      this.setState({
        data,
        creator,
        attendees: [creator, ...attendees],
        meeting: { id, name, attendees, creator }
      });
    }
  }

  closeAttendees = () => {
    this.setState({
      attendees: this.state.attendees.map((attendee, i) => ({
        ...attendee,
        show: false
      }))
    });
  };

  switchAttendees = (option) => {
    this.closeAttendees();

    this.add.toggleMenu(option);
  };

  updateAttendee(index) {
    const { attendees } = this.state;
    this.setState({
      attendees: attendees.map((attendee, i) => {
        if (i === index)
          return {
            ...attendee,
            show: !attendee.show
          };
        return {
          ...attendee,
          show: false
        };
      })
    });
  }

  render() {
    const { attendees, meeting, creator } = this.state;
    return (
      <div className={styles.attendee}>
        {attendees.map((attendee, index) => {
          return (
            <div key={index} className={styles.attendeeLayout}>
              <ListItemAvatar onClick={() => this.updateAttendee(index)}>
                <StyledAvatar
                  firstWord={attendee.first_name}
                  secondWord={attendee.last_name}
                  style={{
                    background: returnColor(attendee.id),
                    width: 32,
                    height: 32,
                    fontSize: 13,
                    marginTop: 0,
                    marginRight: 0
                  }}
                />
              </ListItemAvatar>
              <AttendeeOverlay
                meeting={meeting}
                toggle={() => this.switchAttendees(true)}
                close={() => this.closeAttendees()}
                creator={creator}
                show={attendee.show}
                attendee={attendee}
              />
            </div>
          );
        })}

        <Query query={GET_ALL_USERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return <div>{<ProgessLarge visible />}</div>;
            } else if (error) {
              console.log(error);
              return <p>error</p>;
            } else {
              const { users } = data;
              return meeting.creator ?
                <Show compare={[meeting.creator.id, user_id]}>
                  <AttendeeAdd
                    history={this.props.history}
                    addTask={false}
                    icon={"add-pink"}
                    meeting={meeting}
                    users={users}
                    ref={ad => (this.add = ad)}
                    close={() => this.closeAttendees.bind(this)}
                    attendees={attendees}
                    refetch={this.props.refetch} />
                </Show> : null
            }
          }}
        </Query>
      </div>
    );
  }
}

export default AttendeeMenu;
