export const locale = "nl";
export const dateFormat = "DD-MM-YYYY HH:mm:ss";
export const deadlineDateFormat = "MMM Do";
export const todayDateFormat = "dd, DD MMMM HH:mm";

export const convertDatetoISOFormat = date => {
  if (date) {
    const initial = date
      .toString()
      .substring(0, 11)
      .split(/-/);
    return [initial[1], initial[0], initial[2]].join("/");
  }
};
