import React, { useState,useEffect } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import styles from "./styles.module.scss";
// import { REQUEST_PASSWORD_FORGET } from "../../utils/mutations";
import { Link } from "react-router-dom";
// import { Mutation } from "react-apollo";
import axios from "axios";
import { PRE_CHECK_TOKEN,RESET_PASSWORD } from "../../utils/authRequests";
import ProgressLarge from '../../components/Progress';

const PasswordReset = ({
  history,
  match: {
    params: { token }
  }
}) => {
  const [email, setMail] = useState("");
  const [password, updatePassword] = useState("");
  const [password_confirmation, updatePasswordConfirm] = useState("");
  const [reset_token] = useState(token);
  const [loading, setLoading] = useState(true);
  const requestPassword = e => {
    e.preventDefault();
    updatePassword("");
    setMail("");
    updatePassword("");
    RESET_PASSWORD({email,password,password_confirmation,reset_token}, (status) =>{
      if(status === 200){
        history.push('/')
      } console.log(status)
    })
  };

  useEffect(() => {
    PRE_CHECK_TOKEN(reset_token)
    .then(data => setLoading(false))
    .catch(() => history.push('/password/forget'))
  });

  return (
    <div>
      <Card>
        <div className={styles.header}>
          <Card.Body>
            <Card.Title className={styles.title}>
              <span className={styles.forta}> FORTA </span> TASKMANAGER
            </Card.Title>
          </Card.Body>
        </div>
      </Card>

     {
       loading ? <ProgressLarge visible/> :
       <Form
       onSubmit={e => {
         this.signInRequest(e);
       }}
     >
       <div className={styles.login}>
         <Container>
           <Row>
             <Col md={{ span: 5, offset: 4 }}>
               <label className={styles.formLabel}>
                 Vul je nieuwe wachtwoord toe
               </label>
             </Col>
           </Row>
           <Row>
             <Col md={{ span: 5, offset: 4 }}>
               <input
                 className={styles.input}
                 type="email"
                 name="email"
                 value={email}
                 onChange={e => setMail(e.target.value)}
                 placeholder="Voor je huidige mail"
               />
             </Col>
             <Col md={{ span: 5, offset: 4 }}>
               <input
                 className={styles.input}
                 type="password"
                 name="password"
                 value={password}
                 onChange={e => updatePassword(e.target.value)}
                 placeholder="Voor je nieuwe wachtwoord in"
               />
             </Col>
             <Col md={{ span: 5, offset: 4 }}>
               <input
                 className={styles.input}
                 type="password"
                 name="password2"
                 value={password_confirmation}
                 onChange={e => updatePasswordConfirm(e.target.value)}
                 placeholder="Herhaal je nieuwe wachtwoord"
               />
             </Col>
           </Row>
           <Row>
             <Col md={{ span: 5, offset: 4 }}>
               <div className={styles.formButtons}>
                 <button
                   onClick={e => requestPassword(e)}
                   type="submit"
                   className={styles.firstBtn}
                 >
                   Aanvragen
                 </button>
                 <button className={styles.secondBtn}>
                   <Link to={"/logen"}>Ga terug</Link>
                 </button>
               </div>
             </Col>
           </Row>
         </Container>
       </div>
     </Form>
     }
    </div>
  );
};

export default PasswordReset;
