import React, { useState,useRef, useEffect } from "react";
import { EDIT_TASK } from "../../utils/mutations";
import { Mutation } from "react-apollo";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";
import styles from "./styles.module.scss";

const TaskTitle = ({ meeting, task }) => {
  const wrapperRef = useRef(null);
  const [isTaskTitleEditable, setTaskTitleEditable] = useState(false);
  const [title, setTitle] = useState(task.title);

  const useOutsideAlerter = ref=> {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setTaskTitleEditable(false)
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef);
  
  const handleTaskTitleSubmit = (e, editTask, editNote) => {
    e.preventDefault();
    if (title !== null) {
      editTask({
        variables: {
          title: title,
          id: task.id,
          meeting_list_id: meeting.id
        }
      });
      setTaskTitleEditable(false);
    }
  };
  return (
    <Mutation
      mutation={EDIT_TASK}
      refetchQueries={() => {
        return [
          {
            query: GET_MEETING_WITH_ARCHIVE_YEARS,
            variables: {
              id: meeting.id
            }
          }
        ];
      }}
    >
      {(editTask, { data }) => (
        <div ref={wrapperRef}>
          {" "}
          {isTaskTitleEditable ? (
            <form
              onSubmit={e => {
                handleTaskTitleSubmit(e, editTask);
              }}
            >
              <input
                className={styles.changeTaskTitleInput}
                onChange={e => setTitle(e.target.value)}
                value={title}
              />
            </form>
          ) : (
            <span
              onClick={() => setTaskTitleEditable(true)}
              className={
                `${styles.taskTitle} ` +
                (task.is_completed ? `${styles.taskTitleCompleted}` : null)
              }
            >
              <span className={styles.taskTitle}>{task.title}</span>
            </span>
          )}  
        </div>
      )}
    </Mutation>
  );
};

export default TaskTitle;
