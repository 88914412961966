import * as React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
      component: Component,
      user,
      location,
      path
  }) => {
    return (
        <Route
            render={props => {
                return user ? (
                    <Component {...props} location={location} path={path} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
};

// @ts-ignore
export default PrivateRoute;
