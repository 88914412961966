import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckedIcon from "@material-ui/icons/CheckCircle";
import UncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

export default ({
  children,
  onRadioChange,
  checked,
  disabled = false,
  ...rest
}) => {
  return (
    <FormControlLabel
      {...rest}
      control={
        <Checkbox
          disabled={disabled}
          icon={<UncheckedIcon style={{ color: "rgb(229, 5, 94)" }} />}
          checkedIcon={<CheckedIcon style={{ color: "rgb(229, 5, 94)" }} />}
          checked={checked ? checked : false}
          onChange={onRadioChange}
        />
      }
    />
  );
};
