import React, { Component } from "react";
import MyTask from "../../components/myTask/MyTask";
import Meeting from "../Meeting";
import AddMeeting from "../../components/meetings/AddMeeting";
import styles from "./styles.module.scss";
import Notepad from "../../components/Notepad/index";
import { Route, Link } from "react-router-dom";
import AppMenu from "../../components/AppMenu/AppMenu";
import userCheck from "../../assets/user-check-pink.svg";
import { user_id } from "../../utils/getUserAuth";
import { EDIT_NOTE } from "../../utils/mutations";
import { GET_USER_NOTE } from "../../utils/queries";
import { Query, Mutation } from "react-apollo";
import MobileAppMenu from "../../components/AppMenu/MobileAppMenu";
import { Row, Col, ListGroup, Container, Nav, Navbar } from "react-bootstrap";

class DashBoard extends Component {
  state = {
    isNotePadActive: false
  };

  showSettings(event) {
    event.preventDefault();
  }

  toggleNotePad = () => {
    this.setState(prevState => ({
      isNotePadActive: !prevState.isNotePadActive
    }));
  };
  render() {
    const { isNotePadActive } = this.state;
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xl={2} md={3} className={styles.desktopContainer}>
              <ListGroup className={styles.navBar}>
                <div>
                  <AppMenu />
                </div>
                <hr className={styles.underline} />
                <Link to={"/"} className={styles.myTaskContainer}>
                  <img src={userCheck} alt="" className={styles.userCheck} />
                  <p className={styles.p}>Mijn actiepunten</p>
                </Link>
                <hr className={styles.myTaskunderline} />
                <AddMeeting />
              </ListGroup>
            </Col>
            <Col sm={4} className={styles.mobileContainer}>
              <div>
                <Navbar expand="md" className={styles.navBar}>
                  <Row>
                    <Navbar.Toggle
                      aria-controls="basic-navbar-nav"
                      className={styles.menuIcon}
                    />

                    <h5 className={styles.h5}>
                      <span className={styles.forta}> FORTA </span>
                      TASKMANAGER
                    </h5>
                  </Row>
                  <hr className={styles.underline} />

                  <Navbar.Collapse id="basic-navbar-nav ">
                    <Nav className="flex-column">
                      <Link to={"/"} className={styles.myTaskContainer}>
                        <img
                          src={userCheck}
                          alt=""
                          className={styles.userCheck}
                        />
                        <p className={styles.p}>Mijn actiepunten</p>
                      </Link>

                      <hr className={styles.myTaskunderline} />

                      <AddMeeting />
                      <MobileAppMenu style={{ float: "right" }} />
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
            <Col xl={10} md={9} className={styles.displayContainer}>
              {/* <SortableComponent /> */}
              <Route path="/meeting/:id" component={Meeting} />
              <Route path={"/"} exact component={MyTask} />
            </Col>
            <Query query={GET_USER_NOTE}>
              {({ loading, error: errorNote, data: noteData }) => {
                const { user } = { ...noteData };
                if (loading) {
                  return null;
                }
                if (user) {
                  return (
                    <Mutation mutation={EDIT_NOTE}>
                      {(editNote, { data }) => (
                        <Notepad
                          notePadLabel={"Kladblok"}
                          isActive={isNotePadActive}
                          onClick={this.toggleNotePad}
                          value={user.note !== null ? user.note.text : ""}
                          updateNoteMutation={editedText =>
                            editNote({
                              variables: {
                                text: editedText,
                                user_id
                              }
                            })
                          }
                        />
                      )}
                    </Mutation>
                  );
                }
              }}
            </Query>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DashBoard;
