import React,{useState,useRef, useEffect } from 'react';
import {  EDIT_TASK } from "../../utils/mutations";
import { Mutation  } from "react-apollo";
import { GET_MEETING_WITH_ARCHIVE_YEARS } from "../../utils/queries";

import styles from './styles.module.scss';

const TaskNote = ({meeting,task}) => {
    const wrapperRef = useRef(null);
    const [isNoteTitleEditable,setNoteTitleEditable] = useState(false)
    const [note,setNote] = useState(task.description)

    const useOutsideAlerter = ref => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          setNoteTitleEditable(false)
        }
      }
  
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      });
    } 
    useOutsideAlerter(wrapperRef);

    const handleSubmit = (e,editNote) => {
        e.preventDefault();
        if (note !== null) {
          editNote({
            variables: {
              description: note,
              id: task.id,
              meeting_list_id: meeting.id
            }
          });
          setNoteTitleEditable(false)
        }  
    };

    return(
        <Mutation
        mutation={EDIT_TASK}
        refetchQueries={() => {
          return [
            {
              query: GET_MEETING_WITH_ARCHIVE_YEARS,
              variables: { id: meeting.id }
            },
          ];
        }}
      >
        {(editNote, { data }) => (
          <div ref={wrapperRef}>
            {isNoteTitleEditable ?  (
              <form
                onSubmit={e => {
                  handleSubmit(e, editNote);
                }}
              >
                <input
                  className={styles.changeTaskNoteTitle}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                />
              </form>
            ) : (
              <span
                className={
                  task.is_completed
                    ? `${styles.taskTitleCompleted}`
                    : null
                }
                onClick={() => setNoteTitleEditable(true)}
              >
                <span>{note}</span>
              </span>
            )}
          </div>
        )}
      </Mutation>
    )
};

export default TaskNote;