import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import styles from "./styles.module.scss";
import Plus from "../../assets/plus.svg";
import { GET_MEETINGS } from "../../utils/queries";
import { Query } from "react-apollo";
import Mutation from "react-apollo/Mutation";
import { ADD_MEETING, DELETE_MEETING } from "../../utils/mutations";
import Modal from "react-bootstrap/Modal";
import DeleteButton from "../DeleteButton";
import { Link } from "react-router-dom";
import ProgessSmall from "../../components/Progress/ProgressSmall.tsx";
import { user_id } from "../../utils/getUserAuth";
import AddMeetingCount from "./AddMeetingCount";

class AddMeeting extends Component {
  state = {
    meetings: [],
    isHidden: true,
    isModalOpen: false,
    meetingTitle: null,
    meetingId: null
  };

  toggleHidden = () => {
    this.setState({
      isHidden: !this.state.isHidden
    });
  };

  handleChange = (i, event) => {
    let meetings = [...this.state.meetings];
    meetings[i] = event.target.value;
    this.setState({ meetings });
  };

  handleSubmit = (event, addMeeting) => {
    event.preventDefault();
    addMeeting({ variables: { name: this.newText.value } });
    this.setState({ isHidden: false });
  };

  openModal = (id, title) => {
    this.setState({ isModalOpen: true, meetingTitle: title, meetingId: id });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, meetingTitle: null, meetingId: null });
  };

  deleteMeeting = (mutation, id) => {
    try {
      mutation({
        variables: {
          id: id
        }
      });

      this.setState(
        {
          isModalOpen: false,
          meetingTitle: null,
          meetingId: null
        },
        () => this.props.history.push("/")
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  render() {
    const { isModalOpen } = this.state;

    const Child = () => (
      <div>
        <input
          className={styles.addInput}
          type="text"
          ref={i => {
            this.newText = i;
          }}
        />
      </div>
    );
    return (
      <div style={{ lineHeight: 3.5 }}>
        <h3 className={styles.header}>Actielijsten</h3>
        <Query query={GET_MEETINGS} skip={!user_id} variables={{ user_id }}>
          {({
            loading: loadingMeetings,
            error: errorMeetings,
            data: meetingsData
          }) => {
            if (loadingMeetings) {
              return (
                <div className={styles.progesscontainer}>
                  {<ProgessSmall visible />}
                </div>
              );
            } else if (errorMeetings) {
              return <p>{errorMeetings}</p>;
            } else {
              if (loadingMeetings) {
                return <div>{<ProgessSmall visible />}</div>;
              } else if (errorMeetings) {
                return <p>{errorMeetings}</p>;
              } else {
                const { meetingLists } = meetingsData;
                return meetingLists.map((meeting, index) => {
                  return (
                    <Link
                      to={`/meeting/${meeting.id}`}
                      key={index}
                      className={styles.meetingContainer}
                    >
                      {"●  " + meeting.name}
                      <AddMeetingCount meeting={meeting}/>
                      {meeting.creator.id === user_id && (
                        <DeleteButton
                          onClick={this.openModal}
                          meetingId={meeting.id}
                          meetingTitle={meeting.name}
                        />
                      )}
                    </Link>
                  );
                });
              }
            }
          }}
        </Query>
        <Mutation
          mutation={ADD_MEETING}
          refetchQueries={() => {
            return [
              {
                query: GET_MEETINGS,
                variables: { user_id: user_id }
              }
            ];
          }}
        >
          {(addMeeting, { data }) => (
            <form
              onSubmit={e => {
                this.handleSubmit(e, addMeeting);
                this.toggleHidden();
              }}
            >
              <div className={styles.AddMeetingContainer}>
                {!this.state.isHidden && <Child />}
                <button
                  className={styles.addBtn}
                  type="button"
                  onClick={this.toggleHidden.bind(this)}
                >
                  <Row>
                    <img className={styles.plusIcon} src={Plus} alt=""></img>
                    <p className={styles.p}> Vergadering toevoegen</p>
                  </Row>
                </button>
              </div>
            </form>
          )}
        </Mutation>
        {isModalOpen && (
          <Modal show={isModalOpen} onHide={this.closeModal}>
            <Mutation
              mutation={DELETE_MEETING}
              refetchQueries={() => {
                return [
                  {
                    query: GET_MEETINGS,
                    variables: {
                      user_id: user_id
                    }
                  }
                ];
              }}
            >
              {(deleteMeeting, { data }) => (
                <>
                  <Modal.Body>
                    Wilt u de meeting "{this.state.meetingTitle}" verwijderen?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className={styles.cancelDeleteMeetingPopupBtn}
                      onClick={this.closeModal}
                    >
                      Nee
                    </Button>
                    <Button
                      className={styles.deleteMeetingPopupBtn}
                      onClick={() =>
                        this.deleteMeeting(deleteMeeting, this.state.meetingId)
                      }
                    >
                      Ja
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Mutation>
          </Modal>
        )}
      </div>
    );
  }
}
export default withRouter(AddMeeting);
