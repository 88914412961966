import React from "react";
import Svg from "../../components/Svg";
import styles from "./styles.module.scss";
import { Mutation } from "react-apollo";
import { GET_MEETING_WITH_ARCHIVE_YEARS, GET_MEETINGS } from "../../utils/queries";
import {
  ADD_ATTENDEE_TO_MEETING,
  EDIT_TASK,
  EDIT_MEETING
} from "../../utils/mutations";
// import ProgessLarge from '../../components/Progress'
import StyledAvatar from "../../components/StyledAvatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Fade } from "react-reveal";
import { returnColor } from "../../utils/utils";

class AttendeeAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      users: [],
      search: "",
      filterUsers: [],
      meeting: {},
      icon: "",
      left: false,
      task: {},
      attendee: {},
      addTask: false,
      updateCreator: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  } 

  componentDidMount() {
    const { users, meeting, icon, task, addTask } = this.props;
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState(
      {
        users,
        meeting,
        icon,
        task,
        addTask
      },
      () => this.updatedAttendeed()
    );
  }

  componentDidUpdate(prevProps) {
    const { users, meeting, icon, task, addTask } = this.props;
    if (prevProps !== this.props) {
      this.setState(
        {
          users,
          meeting,
          icon,
          task,
          addTask
        },
        () => this.updatedAttendeed()
      );
    }
  }

  updatedAttendeed() {
    const { meeting, users } = this.state;
    const attendees = [meeting.creator, ...meeting.attendees];
    users.forEach(user =>
      attendees.find(x => x.id === user.id)
        ? (user.attended = true)
        : (user.attended = false)
    );
    users.sort((a, b) => b.attended - a.attended);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  toggleMenu(option) {
    if (this.props.close) {
      this.props.close();
    }
    this.setState({
      open: !this.state.open,
      updateCreator: option === true ? true : false
    });
  }

  closeMenu() {
    this.setState({ open: false });
  }

  handleChange(e) {
    const {
      meeting: { creator, attendees }
    } = this.state;
    const users = () => {
      if (this.state.users !== false) {
        return this.state.users.filter(({ first_name }) => {
          return (
            first_name.toLowerCase().search(this.state.search.toLowerCase()) !==
            -1
          );
        });
      } else {
        return [creator, ...attendees].filter(({ first_name }) => {
          return (
            first_name.toLowerCase().search(this.state.search.toLowerCase()) !==
            -1
          );
        });
      }
    };
    this.setState(
      {
        search: e.target.value
      },
      () => {
        this.setState({ filterUsers: users() });
      }
    );
    e.preventDefault();
  }

  addUserToMeeting(mutation, email, attended, { id }) {
    if (!attended) {
      try {
        console.log(email, id);
        mutation({
          variables: {
            email,
            meeting_id: id
          }
        });
        this.closeMenu();
        this.props.refetch()
      } catch (err) {
        console.log("err", err);
      }
    }
  }

  updateCreator(mutation, meeting, user_id) {
    mutation({
      variables: { id: meeting.id, name: meeting.name, creator_id: user_id }
    });
    this.setState({ updateCreator: false });
    // this.props.refetch()
    this.props.history.push('/')
  }

  updateUserToTask(userId, taskId, meeting, mutation) {
    try {
      mutation({
        variables: {
          id: taskId,
          user_id: userId,
          task_id: taskId,
          meeting_list_id: meeting.id
        }
      });
      this.closeMenu();
    } catch (err) {
      console.log("err", err);
    }
  }

  renderUsers() {
    return <ul className={styles.attendeeList}>{this.renderList()}</ul>;
  }

  renderList() {
    const { filterUsers, users, search, addTask } = this.state;
    if (addTask === false) {
      if (!search || search.length === 0) {
        return this.renderListItem(users);
      } else if (filterUsers.length > 0)
        return this.renderListItem(filterUsers);
      else {
        return <div>empty</div>;
      }
    } else {
      if (!search || search.length === 0) {
        const attendees = users.filter((user) => {
          return user.attended;
        });
        return this.renderCategoryListItem(attendees);
      } else if (filterUsers.length > 0)
        return this.renderCategoryListItem(filterUsers);
      else {
        return <div>empty</div>;
      }
    }
  }

  renderSelfButton() {
    const { meeting, task, addTask } = this.state;
    if (addTask) {
      return (
        <div className={styles.selfButton}>
          <p className={styles.attendeetext}>of</p>
          <Mutation
            mutation={EDIT_TASK}
            refetchQueries={() => {
              return [
                {
                  query: GET_MEETING_WITH_ARCHIVE_YEARS,
                  variables: {
                    id: meeting.id
                  }
                }
              ];
            }}
          >
            {(mutation, data) => (
              <button
                onClick={() =>
                  this.updateUserToTask(
                    meeting.creator.id,
                    task.id,
                    meeting,
                    mutation
                  )
                }
                className={`${styles.attendeeButton} ${styles.attendeeNoMargin}`}
              >
                Mijzelf
              </button>
            )}
          </Mutation>
        </div>
      );
    }
  }

  renderListItem(users) {
    const { meeting, updateCreator } = this.state;
    return users.map(({ id, attended, first_name, last_name, email }) => (
      <div cascade key={id}>
        {updateCreator ? (
          <Mutation
            mutation={EDIT_MEETING}
            refetchQueries={() => {
              return [
                {
                  query: GET_MEETING_WITH_ARCHIVE_YEARS,
                  variables: {
                    id: meeting.id
                  }
                },
                {
                  query: GET_MEETINGS,
                }
              ];
            }}
          >
            {(mutation, data) => (
              <li
                onClick={() => this.updateCreator(mutation, meeting, id)}
                className={
                  `${styles.attendeeItem} ` +
                  (attended ? styles.attendeeDisable : styles.attendeeEnable)
                }
              >
                <ListItemAvatar>
                  <StyledAvatar
                    firstWord={first_name}
                    secondWord={last_name}
                    style={{
                      background: returnColor(id),
                      width: 40,
                      height: 40,
                      fontSize: 13,
                      marginTop: 0,
                      marginRight: 0,
                      marginLeft: attended ? 0 : -2
                    }}
                  />
                </ListItemAvatar>
                <div className={styles.attendeeNames}>
                  <span className={styles.attendeeListName}>
                    {first_name} {last_name}
                  </span>
                  <span className={styles.attendeeListEmail}>{email}</span>
                </div>
              </li>
            )}
          </Mutation>
        ) : (
          <Mutation
            mutation={ADD_ATTENDEE_TO_MEETING}
            refetchQueries={() => {
              return [
                {
                  query: GET_MEETING_WITH_ARCHIVE_YEARS,
                  variables: {
                    id: meeting.id
                  }
                }
              ];
            }}
          >
            {(mutation, data) => (
              <li
                onClick={() =>
                  this.addUserToMeeting(mutation, email, attended, meeting, id)
                }
                className={
                  `${styles.attendeeItem} ` +
                  (attended ? styles.attendeeDisable : styles.attendeeEnable)
                }
              >
                <ListItemAvatar>
                  <StyledAvatar
                    firstWord={first_name}
                    secondWord={last_name}
                    style={{
                      background: returnColor(id),
                      width: 40,
                      height: 40,
                      fontSize: 13,
                      marginTop: 0,
                      marginRight: 0,
                      marginLeft: attended ? 0 : -2
                    }}
                  />
                </ListItemAvatar>
                <div className={styles.attendeeNames}>
                  <span className={styles.attendeeListName}>
                    {first_name} {last_name}
                  </span>
                  <span className={styles.attendeeListEmail}>{email}</span>
                </div>
              </li>
            )}
          </Mutation>
        )}
      </div>
    ));
  }

  renderCategoryListItem(users) {
    const { meeting, task } = this.state;
    return users.map(({ id, attended, first_name, last_name, email }) => (
      <div cascade key={id}>
        <Mutation
          mutation={EDIT_TASK}
          refetchQueries={() => {
            return [
              {
                query: GET_MEETING_WITH_ARCHIVE_YEARS,
                variables: {
                  id: meeting.id
                }
              }
            ];
          }}
        >
          {(mutation, data) => (
            <li
              onClick={() =>
                this.updateUserToTask(id, task.id, meeting, mutation)
              }
              className={styles.attendeeItem}
            >
              <ListItemAvatar>
                <StyledAvatar
                  firstWord={first_name}
                  secondWord={last_name}
                  style={{
                    background: returnColor(id),
                    width: 40,
                    height: 40,
                    fontSize: 13,
                    marginTop: 0,
                    marginRight: 0,
                    marginLeft: attended ? 0 : -2
                  }}
                />
              </ListItemAvatar>
              <div className={styles.attendeeNames}>
                <span className={styles.attendeeListName}>
                  {first_name} {last_name}
                </span>
                <span className={styles.attendeeListEmail}>{email}</span>
              </div>
            </li>
          )}
        </Mutation>
      </div>
    ));
  }

  renderButton(task, icon) {
    if (task && task.assignee) {
      return (
        <ListItemAvatar onClick={this.toggleMenu.bind(this)}>
          <StyledAvatar
            firstWord={task.assignee.first_name}
            secondWord={task.assignee.last_name}
            style={{
              width: 32,
              background: returnColor(task.assignee.id),
              height: 32,
              fontSize: 13,
              marginTop: 0,
              marginRight: 0
            }}
          />
        </ListItemAvatar>
      );
    } else {
      return (
        <Svg
          onClick={this.toggleMenu.bind(this)}
          name={icon}
          style={{
            width: "32px",
            height: "32px",
            fill: "white",
            marginRight: 10,
            cursor: "pointer"
          }}
        />
      );
    }
  }

  render() {
    const { open, icon, task } = this.state;
    console.log(this.props)
    return (
      <div ref={this.setWrapperRef} className={styles.attendeeLayout}>
        {this.renderButton(task, icon)}
        <div
          className={
            styles.attendeeMenu + (open ? ` ${styles.attendeeShow}` : ` `)
          }
        >
          <div className={styles.atteendeeBackground}>
            <div className={styles.attendeeLabelBackground}>
              <input
                name={"search"}
                className={styles.attendeeLabel}
                value={this.state.search}
                onChange={this.handleChange.bind(this)}
                placeholder="Gebruiker zoeken..."
                type="text"
              />
              <Svg
                name="search"
                style={{
                  width: "23px",
                  height: "23px",
                  fill: "white"
                }}
              />
            </div>
            {this.renderSelfButton()}
          </div>
          <div className={styles.AttendeeUsers}>{this.renderUsers()}</div>
        </div>
      </div>
    );
  }
}

export default AttendeeAdd;
