import React,{useState} from 'react';
import CreateTask from "../CreateTask";
import styles from './styles.module.scss';
import { Row } from "react-bootstrap";
import Plus from "../../assets/plus.svg";

const TaskAdd = ({meeting,category,index}) =>{
    const [open,setOpen] = useState(false)
    return open ? (
            <CreateTask
            index={index}
            categoryId={category.id}
            meetingId={meeting.id}
            callback={() => setOpen(!open)}
            toggleCancel={() => setOpen(false)}
          />
           ) : (
             <button
               className={styles.addBtn}
               type="button"
               onClick={() => setOpen(!open)}
             >
               <Row>
                 <img
                   className={styles.plusIcon}
                   src={Plus}
                   alt=""
                 ></img>
                 <p className={styles.p}>{index !== 2 ? "Taak toevoegen" : 'Besluit toevoegen'}</p>
               </Row>
             </button>
           )
};

export default TaskAdd;