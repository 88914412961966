export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    background: isDragging ? "#f7edf1" : "white",

    ...draggableStyle
  };
};

export const getQuestionListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#DFDFDF" : "white"
});
