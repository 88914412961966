import React from "react";
import styles from "./styles.module.scss";
import Task from "./Task";
import { Fade } from "react-reveal";

const Tasks = ({ tasks, meeting, droppable, index, attendees }) => {
  if (tasks) {
    return tasks
      .sort((a, b) => a.order - b.order)
      .map((task, i) => (
        <div key={i}>
          <Task
            key={i}
            droppable={droppable}
            task={task}
            meeting={meeting}
            index={i}
            categoryIndex={index}
            attendees={attendees}
          />
        </div>
      ));
  } else {
    return <p className={styles.rowP}>Geen taken</p>;
  }
};

export default Tasks;
